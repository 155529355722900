<!--
 * @Author: your name
 * @Date: 2020-11-30 10:57:43
 * @LastEditTime: 2020-11-30 10:58:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'PreInventory',
  }
</script>

<style></style>
